'use strict'
export const rolesSerializer = {
  createAdmin : {
    id: 'createAdmin',
    index: 0,
    isChecked: false,
    icon: 'fas fa-user-lock',
    label: 'Create Admin User',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create Admin User',
    removeDesc: 'Remove Admin User',
    updateDesc: 'Deactivate or Delete Admin User',
    viewDesc: 'View List of Admin Users',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  contentProviders: {
    id: 'contentProviders',
    index: 1,
    isChecked: false,
    icon: 'fas fa-chalkboard-teacher',
    label: 'Content Providers',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create Content Providers',
    removeDesc: 'Remove Content Providers',
    updateDesc: 'Update Content Providers',
    viewDesc: 'View List of Content Providers',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  groups: {
    id: 'groups',
    index: 2,
    isChecked: false,
    icon: 'fas fa-users',
    label: 'Groups',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create User Groups',
    removeDesc: 'Remove User Groups',
    updateDesc: 'Update User Groups',
    viewDesc: 'View List of User Groups',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  company: {
    id: 'company',
    index: 3,
    isChecked: false,
    icon: 'far fa-building',
    label: 'Company',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create Company',
    removeDesc: 'Remove Company',
    updateDesc: 'Update Company',
    viewDesc: 'View List of Companies',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  userProfile : {
    id: 'userProfile',
    index: 4,
    isChecked: false,
    icon: 'fas fa-users-cog',
    label: 'User Profile',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create User Profile',
    removeDesc: 'Remove User Profile',
    updateDesc: 'Update User Profile',
    viewDesc: 'User Profile',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  userSearch : {
    id: 'userSearch',
    index: 5,
    isChecked: false,
    icon: 'fas fa-search',
    label: 'User Search',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create User Search',
    removeDesc: 'Remove User Search',
    updateDesc: 'Update User Search',
    viewDesc: 'User Search',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  activateUser : {
    id: 'activateUser',
    index: 6,
    isChecked: false,
    icon: 'fas fa-user-check',
    label: 'Activate User',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'View User Info',
    removeDesc: 'Deactivate Account',
    updateDesc: 'Update Status List',
    viewDesc: 'View User List',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  resetPassword : {
    id: 'resetPassword',
    index: 6,
    isChecked: false,
    icon: 'fas fa-unlock-alt',
    label: 'Reset Password',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'View User Info',
    removeDesc: 'Deactivate Account',
    updateDesc: 'Reset Password',
    viewDesc: 'View User info',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  roles : {
    id: 'roles',
    index: 7,
    isChecked: false,
    icon: 'fas fa-user-shield',
    label: 'Roles and Permissions',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'View User Info',
    removeDesc: 'Deactivate Account',
    updateDesc: 'Reset Password',
    viewDesc: 'View User info',
    createEnable: 1,
    updateEnable: 1,
    viewEnable: 1,
    removeEnable: 1
  },
  setUserRole : {
    id: 'setUserRole',
    index: 8,
    isChecked: false,
    icon: 'fas fa-user-shield',
    label: 'Set User Roles',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create User Roles',
    removeDesc: 'Remove user role',
    updateDesc: 'Create and Set user role',
    viewDesc: 'View User and their Roles',
    createEnable: 1,
    removeEnable: 1,
    updateEnable: 1,
    viewEnable: 1
  },
  changePassword : {
    id: 'changePassword',
    index: 9,
    isChecked: false,
    icon: 'fas fa-lock',
    label: 'Change Password',
    create: false,
    remove: false,
    update: false,
    view: false,
    viewDesc: 'Change Password',
    updateEnable: 1
  },
  dashboard : {
    id: 'dashboard',
    index: 10,
    isChecked: false,
    icon: 'fa fa-dashboard',
    label: 'Dashboard',
    create: false,
    remove: false,
    update: false,
    view: false,
    viewDesc: 'View Dashboard',
    viewEnable: 1
  },
  profile : {
    id: 'profile',
    index: 11,
    isChecked: false,
    icon: 'fas fa-user',
    label: 'User Profile',
    create: false,
    remove: false,
    update: false,
    view: false,
    updateDesc: 'Create and Set user role',
    viewDesc: 'View User and their Roles',
    updateEnable: 1,
    viewEnable: 1
  },
  siteMaintenance : {
    id: 'siteMaintenance',
    index: 12,
    isChecked: false,
    icon: 'fas fa-cogs',
    label: 'Site Maintenance',
    create: false,
    remove: false,
    update: false,
    view: false,
    createDesc: 'Create Maintenance',
    removeDesc: 'Remove Maintenance',
    updateDesc: 'Update Maintenance',
    viewDesc: 'View List if Maintenance',
    createEnable: 1,
    removeEnable: 1,
    updateEnable: 1,
    viewEnable: 1
  }
}
